<template>
  <BCard class="pt-0 p-0">
    <BOverlay
      :show="configs.loading"
      spinner-variant="primary"
      variant="#000000"
    >    
      <BTabs
        v-if="selectedMatricula"
        v-model="configs.currentTab"
        pills
        class="m-0 pb-2"
        style="margin: -10px -10px -26px -10px! important;"
      >
        <!-- ESTUDIANTE -->
        <BTab active lazy>
          <template #title class="danger">
            <feather-icon
              icon="UserCheckIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline ">Estudiante</span>
          </template>
          <TabEstudiante
            ref="tabEstudiante"
            class="border-top pt-50"
            crud="u"
            :matricula="selectedMatricula"
          />
        </BTab>

        <!-- APODERADO -->
        <BTab lazy>
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Apoderado</span>
          </template>
          <TabApoderado
            ref="tabApoderado"
            class="border-top pt-50"
            crud="u"
            :matricula="selectedMatricula"
          />
        </BTab>

        <!-- FAMILIAR -->
        <BTab lazy>
          <template #title>
            <feather-icon
              icon="UsersIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Núcleo Familiar</span>
          </template>
          <TabFamiliar
            ref="tabFamiliar"
            class="border-top pt-50"
            crud="u"
            :matricula="selectedMatricula"
          />
        </BTab>

        <!-- SALUD -->
        <BTab lazy>
          <template #title>
            <feather-icon
              icon="Icon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Antecedentes de Salud</span>
          </template>
          <TabSalud
            ref="tabSalud"
            class="border-top pt-50"
            crud="u"
            :matricula="selectedMatricula"
          />
        </BTab>

      </BTabs>

      <!-- Action Buttons -->
      <div class="border-top pt-1 mt-1 row">
        <div class="col-12 col-md-8"></div>
        <div class="col-12 col-md-4 text-right">
          <BButtonGroup>
            <btnSubmit
              :btnText="btnTxt"
              modulo="matriculas"
              class="rounded"
              variant="primary"
              @processBtn="onSubmit"
            />
          </BButtonGroup>
        </div>
      </div>
    </BOverlay>
  </BCard>
</template>

<script>
// Etiquetas //
import {
  BCard, BTabs, BTab, BRow, BCol, BButtonGroup, BOverlay,
} from 'bootstrap-vue'

import { mapActions, mapState } from 'vuex'

// Componentes hijos
import TabEstudiante from './MatriculasFormTabs/TabEstudiante.vue'
import TabApoderado from './MatriculasFormTabs/TabApoderado.vue'
import TabFamiliar from './MatriculasFormTabs/TabFamiliar.vue'
import TabSalud from './MatriculasFormTabs/TabSalud.vue'

// Componente reciclado
import btnSubmit from '@/views/components/Form/btnSubmit.vue';

export default {
  components: {
    BCard, BTabs, BTab, BRow, BCol, BButtonGroup, BOverlay,

    // Componentes hijos
    TabEstudiante,
    TabApoderado,
    TabFamiliar,
    TabSalud,

    // Componente reciclado
    btnSubmit,
  },
  data() {
    return {
      configs: {
        loading: false,
        currentTab: null,
      }
    }
  },
  computed: {
    ...mapState('matriculas', ['selectedMatricula']),
    btnTxt() {
      switch (this.configs.currentTab) {
        case 0:
          return 'Guardar Matrícula';
        case 1:
          return 'Guardar Apoderado';
        case 2:
          return 'Guardar Núcleo Familiar';
        case 3:
          return 'Guardar Antecedentes de Salud';
        default:
          return 'Guardar';
      }
    },
  },
  methods: {
    ...mapActions({
      updateMatricula: 'matriculas/updateMatricula',
      updateMatriculaApoderado: 'matriculas/updateMatriculaApoderado',
      createMatriculaSalud: 'matriculas/addMatriculaSalud',
      createMatriculaNucleo: 'matriculas/addMatriculaNucleo',
    }),
    onSubmit() {
      if (this.configs.currentTab === 0) {
        this.validaEstudiante();
      }
      if (this.configs.currentTab === 1) {
        this.validaApoderado();
      }
      if (this.configs.currentTab === 2) {
        this.validaFamiliar();
      }
      if (this.configs.currentTab === 3) {
        this.validaSalud();
      }
    },
    validaEstudiante() {
      return new Promise(() => {
        if (this.$refs.tabEstudiante.validarFormulario()) {
          this.submitEstudiante()
        }
      });
    },
    validaApoderado() {
      return new Promise(() => {
        if (this.$refs.tabApoderado.validarFormulario()) {
          this.submitApoderado()
        }
      });
    },
    validaFamiliar() {
      return new Promise(() => {
        if (this.$refs.tabFamiliar.validarFormulario()) {
          this.submitFamiliar();
        }
      });
    },
    validaSalud() {
      return new Promise(() => {
        if (this.$refs.tabSalud.validarFormulario()) {
          this.submitSalud()
        }
      });
    },

    async submitEstudiante() {
      this.configs.loading = true
      const estudiante = this.$refs.tabEstudiante.getEstudiante()
      const response = await this.updateMatricula({...this.selectedMatricula, ...estudiante})
      if (response.status === 'success') {
        this.$swal({
          title: 'Matrícula guardada 👍',
          html: 'La matrícula fue editada con éxito! <br>¿Cómo deseas continuar?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Continuar editando la matrícula del estudiante',
          cancelButtonText: 'Salir',
          customClass: {
            confirmButton: 'btn btn-outline-primary mb-1',
            cancelButton: 'btn btn-outline-secondary mb-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.configs.loading = false
          } else {
            this.$router.push({
              name: 'matriculas',
            })
          }
        })
                
      } else if (response.status === 'error') {
        
        this.$swal({
          title: 'Error!',
          text: response.message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        this.configs.loading = false
      }

    },
    async submitApoderado() {
      this.configs.loading = true
      const apoderado = this.$refs.tabApoderado.getApoderado()
      const response = await this.updateMatriculaApoderado({ id_matricula: this.selectedMatricula.id_matricula, ...apoderado})
      if (response.status === 'success') {
        this.$swal({
          title: 'Apoderado guardado 👍',
          html: 'El apoderado fue editado con éxito! <br>¿Cómo deseas continuar?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Continuar editando la matrícula del estudiante',
          cancelButtonText: 'Salir',
          customClass: {
            confirmButton: 'btn btn-outline-primary mb-1',
            cancelButton: 'btn btn-outline-secondary mb-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.configs.loading = false
          } else {
            this.$router.push({
              name: 'matriculas',
            })
          }
        })
      } else {
        this.$swal({
          title: 'Error!',
          text: response.message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        this.configs.loading = false
      }
    },
    async submitFamiliar() {
      this.configs.loading = true
      const familiar = this.$refs.tabFamiliar.getFamiliar()
      const response = await this.createMatriculaNucleo({...familiar, id_matricula: this.selectedMatricula.id_matricula })
      if (response.status === 'success') {
        this.$swal({
          title: 'Núcleo familiar guardado 👍',
          html: 'El núcleo familiar fue editado con éxito! <br>¿Cómo deseas continuar?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Continuar editando la matrícula del estudiante',
          cancelButtonText: 'Salir',
          customClass: {
            confirmButton: 'btn btn-outline-primary mb-1',
            cancelButton: 'btn btn-outline-secondary mb-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.configs.loading = false
          } else {
            this.$router.push({
              name: 'matriculas',
            })
          }
        })
      } else {
        this.configs.loading = false
        this.$swal({
          title: 'Error!',
          text: response.message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
    },
    async submitSalud() {
      this.configs.loading = true
      const salud = this.$refs.tabSalud.getSalud()
      const response = await this.createMatriculaSalud({...salud, id_matricula: this.selectedMatricula.id_matricula })
      if (response.status === 'success') {
        this.$swal({
          title: 'Antecedentes de salud guardados 👍',
          html: 'Los antecedentes de salud fueron editados con éxito! <br>¿Cómo deseas continuar?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Continuar editando la matrícula del estudiante',
          cancelButtonText: 'Salir',
          customClass: {
            confirmButton: 'btn btn-outline-primary mb-1',
            cancelButton: 'btn btn-outline-secondary mb-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.configs.loading = false
          } else {
            this.$router.push({
              name: 'matriculas',
            })
          }
        })
      }
      else {
        this.$swal({
          title: 'Error!',
          text: response.message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        this.configs.loading = false
      }
    },
  },
}
</script>

<template>
  <div>
    <div class="d-flex justify-content-between mb-3">
      <div class="ml-1 mt-50 text-secondary">
        Antecedentes del núcleo familiar de el/la estudiante
      </div>
      <div class="mr-1 mt-50 text-muted small">Campos obligatorios <span class="text-danger">*</span></div>
    </div>

    <!-- FORM -->
    <BOverlay
      :show="cargando"
      spinner-variant="primary"
      variant="#000000"
    >
      <BRow class="ml-25 mr-25">
        <BCol cols="6">
          <BRow>

            <!-- INTEGRANTES -->
            <BCol cols="12">
              <BFormGroup
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label-for="integrantes"
              >
                <template #label>
                  Integrantes <span class="text-danger">*</span>
                </template>
                <BFormInput
                  id="integrantes"
                  v-model="familiar.integrantes"
                  placeholder="Ingrese el número de integrantes"
                  :state="v$.familiar.integrantes.$error === true
                  ? false
                  : null"
                  @blur.native="v$.familiar.integrantes.$touch"
                  @keyup="familiar.integrantes = formatSoloNumeros(familiar.integrantes)"
                />
                <!-- Validaciones -->
                <BFormInvalidFeedback
                  v-if="v$.familiar.integrantes.$error"
                  id="integrantesInfo"
                  class="pb-0"
                >
                  <p
                    v-for="error of v$.familiar.integrantes.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </BFormInvalidFeedback>
              </BFormGroup>
            </BCol>

            <!-- CAMAS -->
            <BCol cols="12">
              <BFormGroup
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label-for="camas"
              >
                <template #label>
                  Cantidad de camas  <span class="text-danger">*</span>
                </template>
                <BFormInput
                  id="camas"
                  v-model="familiar.camas"
                  placeholder="Ingrese el número de camas"
                  :state="v$.familiar.camas.$error === true
                  ? false
                  : null"
                  @blur.native="v$.familiar.camas.$touch"
                  @keyup="familiar.camas = formatSoloNumeros(familiar.camas)"
                />
                <!-- Validaciones -->
                <BFormInvalidFeedback
                  v-if="v$.familiar.camas.$error"
                  id="integrantesInfo"
                  class="pb-0"
                >
                  <p
                    v-for="error of v$.familiar.camas.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </BFormInvalidFeedback>
              </BFormGroup>
            </BCol>

            <!-- HACINAMIENTO -->
            <BCol cols="12">
              <BFormGroup
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label-for="hacinamiento"
              >
                <template #label>
                  Hacinamiento  <span class="text-danger">*</span>
                </template>
                <BFormInput
                  id="hacinamiento"
                  v-model="familiar.hacinamiento"
                  placeholder="Ingrese el hacinamiento"
                  :state="v$.familiar.hacinamiento.$error === true
                  ? false
                  : null"
                  @blur.native="v$.familiar.hacinamiento.$touch"
                  @keyup="familiar.hacinamiento = formatSoloNumeros(familiar.hacinamiento)"
                />
                <!-- Validaciones -->
                <BFormInvalidFeedback
                  v-if="v$.familiar.hacinamiento.$error"
                  id="integrantesInfo"
                  class="pb-0"
                >
                  <p
                    v-for="error of v$.familiar.hacinamiento.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </BFormInvalidFeedback>
              </BFormGroup>
            </BCol>

          </BRow>
        </BCol>
        <BCol cols="6">
          <BRow>

            <!-- NIVEL EDUCACIONAL PADRE -->
            <BCol cols="12">
              <BFormGroup
                content-cols="8"
                label-class="font-weight-bold mt-25 text-right"
                label-for="nivel_educacional_padre"
              >
                <template #label>
                  Educación del padre <span class="text-danger">*</span>
                </template>
                <v-select
                  v-model="familiar.nivel_educacional_padre"
                  placeholder="Seleccione la educación"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :reduce="option => option.nivel_educacional"
                  :options="optionsNivelEducacion"
                  :class="v$.familiar.nivel_educacional_padre.$error === true
                    ? 'border-danger rounded'
                    : ''"
                />
                  <!-- :disabled="optionsPaises.length === 0" -->
                <div
                  v-if="v$.familiar.nivel_educacional_padre.$error"
                  id="asistentesInfo"
                  class="text-danger text-left"
                  style="font-size: 0.857rem;"
                >
                  <p v-for="error of v$.familiar.nivel_educacional_padre.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </p>
                </div>
              </BFormGroup>
            </BCol>

            <!-- NIVEL EDUCACIONAL PADRE -->
            <BCol cols="12">
              <BFormGroup
                content-cols="8"
                label-class="font-weight-bold mt-25 text-right"
                label-for="nivel_educacional_madre"
              >
                <template #label>
                  Educación del la madre <span class="text-danger">*</span>
                </template>
                <v-select
                  v-model="familiar.nivel_educacional_madre"
                  placeholder="Seleccione la educación"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :reduce="option => option.nivel_educacional"
                  :options="optionsNivelEducacion"
                  :class="v$.familiar.nivel_educacional_madre.$error === true
                    ? 'border-danger rounded'
                    : ''"
                />
                  <!-- :disabled="optionsPaises.length === 0" -->
                <div
                  v-if="v$.familiar.nivel_educacional_madre.$error"
                  id="asistentesInfo"
                  class="text-danger text-left"
                  style="font-size: 0.857rem;"
                >
                  <p v-for="error of v$.familiar.nivel_educacional_madre.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </p>
                </div>
              </BFormGroup>
            </BCol>

          </BRow>
        </BCol>
      </BRow>

      <BRow class="ml-25 mr-25 mt-2">

        <!-- SUB TITULO: Entrega de certificados -->
        <BCol
          cols="3"
          class="mb-2 mt-2"
        >
          <BCard-sub-title>
            Entrega de documentos
          </BCard-sub-title>
        </BCol>
        <BCol
          cols="9"
          class="mb-1 mt-2"
        >
          <BCard-sub-title >
            <hr class="mt-75">
          </BCard-sub-title>
        </BCol>

        <!-- CERTIFICADO DE NACIMIENTO -->
        <BCol cols="4">
          <BFormGroup
            label-class="font-weight-bold text-center"
            class="text-center"
            label="Certificado de nacimiento"
            label-for="certificado_nacimiento"
          >
            <!-- label-cols-md="6" -->
            <BFormCheckbox
              checked="true"
              v-model="familiar.certificado_nacimiento"
              class="custom-control-success"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
            </BFormCheckbox>
          </BFormGroup>
        </BCol>

        <!-- CERTIFICADO DE ESTUDIO -->
        <BCol cols="4">
          <BFormGroup
            label-class="font-weight-bold text-center"
            class="text-center"
            label="Certificado de estudio"
            label-for="certificado_estudio"
          >
            <!-- label-cols-md="6" -->
            <BFormCheckbox
              checked="true"
              v-model="familiar.certificado_estudio"
              class="custom-control-success"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
            </BFormCheckbox>
          </BFormGroup>
        </BCol>

        <!-- CERTIFICADO DE PERSONALIDAD -->
        <BCol cols="4">
          <BFormGroup
            label-class="font-weight-bold text-center"
            class="text-center"
            label="Certificado de personalidad"
            label-for="certificado_personalidad"
          >
            <!-- label-cols-md="6" -->
            <BFormCheckbox
              checked="true"
              v-model="familiar.certificado_personalidad"
              class="custom-control-success"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
            </BFormCheckbox>
          </BFormGroup>
        </BCol>

      </BRow>
    </BOverlay>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend,
  BCardSubTitle, BFormCheckbox, BButtonGroup, BOverlay, BFormInvalidFeedback,
} from 'bootstrap-vue'

import { mapActions, mapGetters } from 'vuex'

import vSelect from 'vue-select'

// MIXINS
import { rut } from '@/mixins/rut'
import { formatos } from '@/mixins/formatos'

// VALIDACIONES //
import useVuelidate from '@vuelidate/core'
import { required, maxLength, helpers } from '@vuelidate/validators'

// Componentes reciclados
import colLinea from '../../../components/Form/colLinea.vue';
import btnSubmit from '../../../components/Form/btnSubmit.vue';

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend,
    BCardSubTitle, BFormCheckbox, BButtonGroup, BOverlay, BFormInvalidFeedback,
    vSelect,

    // Componentes reciclados
    colLinea,
    btnSubmit,
  },
  mixins: [rut, formatos],
  data() {
    return {
      // data
      cargando: true,
      familiar: {
        camas: null,
        certificado_estudio: false,
        certificado_nacimiento: false,
        certificado_personalidad: false,
        hacinamiento: null,
        id_persona_rol_madre: null,
        id_persona_rol_padre: null,
        integrantes: null,
        nivel_educacional_madre: null,
        nivel_educacional_padre: null,
        renta: null,
        vive: null,
      },
      // options
      optionsNivelEducacion: [
        {
          nivel_educacional: 1,
          title: 'Básica',
        },
        {
          nivel_educacional: 2,
          title: 'Básica - Incompleta',
        },
        {
          nivel_educacional: 3,
          title: 'Media',
        },
        {
          nivel_educacional: 4,
          title: 'Media - Incompleta',
        },
        {
          nivel_educacional: 5,
          title: 'Técnico Nivel Superior',
        },
        {
          nivel_educacional: 6,
          title: 'Técnico Nivel Superior - Incompleta',
        },
        {
          nivel_educacional: 7,
          title: 'Universitaria',
        },
        {
          nivel_educacional: 8,
          title: 'Universitaria - Incompleta',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getMatriculaNucleo: 'matriculas/getMatriculaNucleo',
    }),
  },
  props: {
    matricula: {
      type: Object,
      required: true,
    },
    crud: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.cargaFamiliar()
  },
  methods: {
    ...mapActions({
      fetchMatriculaNucleo: 'matriculas/fetchMatriculaNucleo',
      createMatriculaNucleo: 'matriculas/addMatriculaNucleo',
    }),
    async cargaFamiliar() {
      this.cargando = true
      await this.fetchMatriculaNucleo(this.matricula.id_matricula)
      if (this.getMatriculaNucleo.status !== 'error') {
        this.familiar = {
          vive: this.getMatriculaNucleo.vive,
          renta: this.getMatriculaNucleo.renta,
          integrantes: this.getMatriculaNucleo.integrantes,
          camas: this.getMatriculaNucleo.camas,
          hacinamiento: this.getMatriculaNucleo.hacinamiento,
          certificado_nacimiento: this.getMatriculaNucleo.certificado_nacimiento === 1,
          certificado_estudio: this.getMatriculaNucleo.certificado_estudio === 1,
          certificado_personalidad: this.getMatriculaNucleo.certificado_personalidad === 1,
          nivel_educacional_padre: this.getMatriculaNucleo.nivel_educacional_padre,
          nivel_educacional_madre: this.getMatriculaNucleo.nivel_educacional_madre,
          id_persona_rol_padre: this.getMatriculaNucleo.id_persona_rol_padre,
          id_persona_rol_madre: this.getMatriculaNucleo.id_persona_rol_madre,
        }
      }
      this.cargando = false
    },
    
    // SON LLAMADOS DESDE EL PADRE
    validarFormulario() {
      this.v$.familiar.$touch()
      return !this.v$.familiar.$invalid;
    },
    getFamiliar() {
      return this.familiar;
    },

  },
  validations() {
    return {
      familiar: {
        integrantes: {
          required: helpers.withMessage('El campo es requerido.', required),
          // maxLength: helpers.withMessage('Debe tener máximo 150 caracteres.', maxLength(150)),
        },
        camas: {
          required: helpers.withMessage('El campo es requerido.', required),
          // maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
        hacinamiento: {
          required: helpers.withMessage('El campo es requerido.', required),
          // maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
        nivel_educacional_padre: {
          required: helpers.withMessage('El campo es requerido.', required),
          // maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
        nivel_educacional_madre: {
          required: helpers.withMessage('El campo es requerido.', required),
          // maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
      },
    }
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
